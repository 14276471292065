.map{
    width: 30vw;
    height: 30vw;
}

@media screen and (max-width: 480px) {
    .map{
        width: 80vw;
        height: 80vw;
    }
}