body{
    font-family: 'Jost', sans-serif;
    font-family :system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1{
    font-size: 35px;
    font-weight: bold;
}

.headColor{
    color:#21225F;
}
.shape {
 
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.4s, transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.4s;
    height:35vw;
    width: 35vw;
  }
  .bg-cover {
    /* margin: auto 1vw; */
      height: 35vw;
    width: 35vw;
     background-image: url("../../public/images/enrolled-img3.jpg");
       -webkit-animation: liquid 7s ease-in-out infinite;
      animation: liquid 7s ease-in-out infinite;
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    background-size:cover;
    
  }
  @keyframes liquid {
  0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
     
  }
  50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    
  }
  100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      
  }
    }

    @media screen and (max-width:1024px) {
        .shape{
            width: 80vw;
            height: 80vw;
        }
        .bg-cover{
            width: 80vw;
            height: 80vw;
        }
        .split-screen{
            flex-direction: column;
        }
    }

/* styles.css */
/* styles.css */


.split-screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    flex-wrap: wrap;
}

.split-screen .text {
    width: 45%;
    padding: 20px;
}

.split-screen .image {
    width: 45%;
    overflow: hidden;
}

.split-screen .image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.accordion {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
}

.testimonials {
    padding: 50px 0;
}

.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 20px 0;
    position: relative;
}

.marquee-content {
    display: flex;
    animation: marquee 10s linear infinite;
  }
/* .marquee img {
    display: inline-block;
    height: 50px;
    margin: 0 20px;
    animation: marquee 10s linear infinite;
} */

@keyframes marquee {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}


/* Example styles */
.ql-editor {
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.ql-editor h1 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.ql-editor h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.ql-editor h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

/* styles.css */

/* Adjustments for React Quill */
  .ql-editor {
    font-size: 20px;
    /* line-height: 40px; */
    height: fit-content;
  }
  
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  .ql-editor img{
    width: 30vw;
  }

  .ql-editor blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    padding-left: 10px;
    font-style: italic;
    color: #777;
  }
  
  
  /* File input styles */
  .file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    background-color: #6f7c94;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.3s ease;
    
  }
  
  .file-input:hover {
    background-color: #475469;
  }
  

/* Add more styles as needed */


.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
