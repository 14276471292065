.testimonial-slider-container {
    padding: 10px;
}

.testimonial-slide {
    padding: 0 20px;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.slick-center .testimonial-slide {
    transform: scale(1.1);
    opacity: 1;
}

.slick-slide:not(.slick-center) .testimonial-slide {
    transform: scale(0.9);
    opacity: 0.6;
}

.testimonial-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 10px;
}

.quote-icon {
    position: absolute;
    top: 20px;
    left: 10px;
    font-size: 2rem;
    color: #ccc;
}

.photo-container {
    width: 45%;
    margin-bottom: 20px;
}

.photo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.testimonial-text {
    color: #333;
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
}

.testimonial-info {
    text-align: center;
    margin-bottom: 10px;
}

.name {
    font-weight: bold;
    color: #555;
}

.designation {
    color: #777;
}

.rating {
    display: flex;
    justify-content: center;
}

.divHeight {
    height: 950px;
    padding-top: 30px;
}

.active {
    border: none;
}

.program-card {
    height: 600px;
}

.accordion {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
}


.content {
    height: 120vh;
}

@media screen and (min-width:760px) {
    .divHeight {
        height: 500px;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
    }

    .photo {
        width: 50%;
    }

    .program-card {
        height: 610px;
    }

    .content {
        height: 40vh;
        margin-top: 5vh;
    }

    

}

@media screen and (min-width:1024px) {
    .divHeight {
        height: 500px;
	width: 60%;
    }

    .photo {
        width: 40%;
    }

    .program-card {
        height: 600px;
    }

    .content {
        margin-top: 0;
    }

    .parallax h1 {
        position: relative;
        z-index: 2;
        font-size: 3rem;
    }
}
@media screen and (min-width: 1200px) {
    .divHeight{
	width: 100%;
	height: 600px;
    }
}
@media screen and (min-width:1500px) {
    .divHeight {
        height: 600px;
    }
    .program-card {
	height:550px;
    }
}
.gradient {
    background-image: linear-gradient(to right, #4755690a, #9d0cb28a, #4343c899, #4755690a);
    width: 50%;
    height: 0.094rem;
    position: absolute;
    content: "";
    bottom: -0.063rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0.125rem 0.75rem #4343c84d;
}


.parallax {
    position: relative;
    background: url('../../public/images/bg.jpg') no-repeat center center fixed;
    background-size: cover;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 20px;
    overflow: hidden;
}

.parallax::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(31, 41, 55, 0.8);
    /* Adjust the opacity and color as needed */
    z-index: 1;
}

.parallax h1 {
    position: relative;
    z-index: 2;
    font-size: 2rem;
}

























.caption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.event-card:hover .caption {
    white-space: wrap;
    overflow: visible;
}

.p-6:hover .caption-hover {
    display: block;
}
